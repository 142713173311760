import {
	DataHandlerDevice,
	Device,
	type BaseEidosType,
	Scene,
	Lightshow,
	DataHandlerLightshow,
	DataHandlerScene,
	TimetableEvent,
	DeviceGroup,
	ProjectorPowerManager,
	DeviceRPi,
	DeviceSpotlightManager,
} from "../../index"
import { DateTime } from "luxon"
import { Controller } from "svelte-comps/stores"
import { APIThread } from "./DeviceAPIThread"

type DevicePlaybackStatus_Data = {
	scene: Scene
	lightshow: Lightshow
	event: TimetableEvent
}

type DevicePlaybackStatus_Playing = {
	isPlaying: true
} & DevicePlaybackStatus_Data

type DevicePlaybackStatus_Empty = {
	isPlaying: false
} & Partial<DevicePlaybackStatus_Data>

type DevicePlaybackStatus =
	| DevicePlaybackStatus_Playing
	| DevicePlaybackStatus_Empty

/**
 * Handles a device group play operation for a group who's children have API_THREAD compatibility
 */
class GroupPlaybackOperator {
	public group: DeviceGroup
	public show: Scene | Lightshow
	public loopCount: number

	constructor(
		group: DeviceGroup,
		show: Scene | Lightshow,
		loopCount: number = 1
	) {
		this.group = group
		this.show = show
		this.loopCount = loopCount
	}

	/**
	 * Gets the master device
	 * @returns the master device
	 */
	private getMaster(): DeviceRPi {
		const potentialMasters = this.group
			.getChildDevices()
			.filter((dev) => dev.features.includes("API_THREADS"))

		let master: DeviceRPi = potentialMasters.find(
			(dev) => dev.eidos?.lux2lux?.role === "master"
		)
		if (master == null)
			throw new APIThread.APIThreadFailedError(
				this.group,
				"master_play_scene",
				"ERR_CLIENT_UNREACHABLE"
			)

		return master
	}

	/**
	 * Begins the playback operation
	 * @returns promise to be resolved once the playback ends
	 */
	public async play() {
		const master = this.getMaster()

		const oldAPIThread = master.eidos?.api_threads?.master_play_scene
		let ignoredThreadID: number = undefined
		if (
			oldAPIThread &&
			(oldAPIThread.status == "SUCCESS" || oldAPIThread.status == "ERROR")
		) {
			console.warn("Ignoring old master_play_scene thread")
			ignoredThreadID = oldAPIThread.id
		}

		if (!oldAPIThread || oldAPIThread.status != "RUNNING")
			await master.platoCall("play_scene", [
				this.show.id,
				this.loopCount,
				false,
			])

		return APIThread.waitFor(master, "master_play_scene", ignoredThreadID)
	}
}

class DevPlaybackManager extends Controller<{
	[index: number]: DevicePlaybackStatus
}> {
	// store type - index: device ID, value: playback status

	constructor() {
		super({})
	}

	/**
	 * Loop through all user devices
	 * (1) create initial status blocks for each device
	 * (2) hook into eidos updates to keep status blocks up to date
	 */
	async initialize() {
		let initState: { [index: number]: DevicePlaybackStatus } = {}
		for (const device of DataHandlerDevice.getMany()) {
			initState[device.id!] = await this.getPlaybackStatus(device) // get initial playback state
			if (device instanceof DeviceGroup) {
				const children = device.getChildDevices()

				children.forEach((child) => {
					child.addUpdateListener(async (d) => {
						const playbackStatus = await this.getPlaybackStatus(
							device
						)
						this.store.update((ctx) => ({
							...ctx,
							[device.id]: playbackStatus,
						}))
					})
				})
			} else {
				device.addUpdateListener(async (dev) => {
					const playbackStatus = await this.getPlaybackStatus(dev)
					this.store.update((ctx) => ({
						...ctx,
						[device.id]: playbackStatus,
					}))
				})
			}
		}
		this.store.set(initState)
	}

	/**
	 * Returns a boolean represenetation of if the specified device is currently playing anything
	 * @param eidos the device eidos
	 * @returns boolean representation of current playing status
	 */
	public checkIsPlaying = (device: Device) => {
		if (device instanceof DeviceGroup)
			return this.checkIsPlayingForGroup(device)
		const eidos = device.eidos
		if (!eidos) return false
		if (eidos.display_mode === "PREVIEW") return true
		if (eidos.display_mode === "TIMETABLE") {
			if (eidos.playback_type === "EMPTY") return false
			if (eidos.playback_type === "SCENE") return true
		} else return false
	}

	public checkIsPlayingForGroup = (device: DeviceGroup) => {
		const children = device.getChildDevices()
		for (const child of children) {
			const eidos = child.eidos
			if (eidos?.display_mode === "PREVIEW") return true
			if (eidos?.display_mode === "STATIC" && eidos?.status === "PROJECT")
				return true
			if (
				eidos?.display_mode === "TIMETABLE" &&
				eidos?.playback_type === "SCENE"
			)
				return true
		}
		return false
	}

	/**
	 * Sends the specified device a command to start playing a show. If provided, callbacks will be triggered under specific circumstances.
	 * @param device The device to play the show on
	 * @param show The show to play
	 * @param callbacks An object with callbacks to be triggered under specific circumstances.
	 * @param callbacks.onPoweringProjector Triggers if the internal projector needs to be powered on first
	 * @param callbacks.onFirstDownload Triggers if the device has never downloaded the specified show
	 * @param callbacks.onPlayingOutdated Triggers if the device has an old version of the show (it will begin playing that first)
	 */
	public playOnDevice = async (
		device: Device,
		show: Scene | Lightshow,
		callbacks: {
			onPoweringProjector?: () => void
			onFirstDownload?: () => void
			onPlayingOutdated?: () => void
		} = {}
	) => {
		let scene = show
		// if (show instanceof Lightshow) scene = show.getScenesInOrder()[0]
		// else scene = show

		const POWERED_OFF_STATES = ["OFF", "POWERING_OFF", "POWERING_ON"]
		const { onPoweringProjector, onFirstDownload, onPlayingOutdated } =
			callbacks

		let needProjectorPower = false
		if (device instanceof DeviceGroup) {
			needProjectorPower = POWERED_OFF_STATES.includes(
				ProjectorPowerManager.get(
					device.getChildDevices().find((dev) => dev.isOnline).id
				).state
			)
		} else {
			needProjectorPower = POWERED_OFF_STATES.includes(
				ProjectorPowerManager.get(device.id).state
			)
		}

		// Handle powering internal projector
		if (needProjectorPower) {
			if (onPoweringProjector) onPoweringProjector()
			if (device instanceof DeviceRPi || device instanceof DeviceGroup)
				await device.setProjectorPower("ON")
		}

		if (
			device instanceof DeviceGroup &&
			device.features.includes("API_THREADS")
		) {
			const playbackOperator = new GroupPlaybackOperator(device, scene, 1)
			return await playbackOperator.play()
		}

		// Handle show needing download
		if (!device.isReadyToPlay(scene)) {
			if (onFirstDownload) onFirstDownload()
			await this.downloadShow(device, show)
		}
		// Disable spotlight
		if (DeviceSpotlightManager.getSpotlightStatus(device))
			await DeviceSpotlightManager.deactivateSpotlight(device)
		// If show has been downloaded, but a new version is available
		if (device.isShowOutdated(show)) {
			if (onPlayingOutdated) onPlayingOutdated()
			await device.previewShow(show, 1, true)
			await this.downloadShow(device, show)
		}

		await device.previewShow(show, 1)
	}

	private _downloadShow = (
		dev: DeviceRPi,
		show: Scene | Lightshow,
		group?: DeviceGroup
	) => {
		if (dev.isShowDownloading(show)) return
		return new Promise<void>((res) => {
			if (!dev.isShowDownloading(show))
				dev.platoCall("download_show", [show.id])
			let interval = setInterval(() => {
				if (!group?.isShowOutdated(show)) {
					clearInterval(interval)
					res()
				}
			}, 1000)
		})
	}

	downloadShow = async (device: Device, show: Scene | Lightshow) => {
		if (device instanceof DeviceRPi) {
			return this._downloadShow(device, show)
		} else if (device instanceof DeviceGroup) {
			const devices = device.getChildDevices()

			const promises = devices.map((dev) => {
				this._downloadShow(dev as DeviceRPi, show)
			})

			return Promise.all(promises)
		}
	}

	public cancelPreview = async (device: Device) => {
		await device.clearPreview()
	}

	/**
	 * Gets the currently active event
	 * @param device
	 * @returns
	 */
	public getActiveEvent = async (device: Device) => {
		const findEvent = (showID: number, startTime: number) => {
			const start = DateTime.fromSeconds(startTime, { zone: "utc" })
			const events = device.timetableManager.getEventsInOrder(50, {
				from: start.minus({ minutes: 20 }),
				to: start.plus({ minutes: 20 }),
			})
			return events.find((e) => e.event.showId === showID)?.event
		}

		let eidos: BaseEidosType
		if (device instanceof DeviceGroup) {
			const child = device.getChildDevices().find((dev) => dev.isOnline)
			eidos = child?.eidos
		} else {
			eidos = device.eidos
		}

		if (!eidos) return undefined
		if (isNaN(eidos.proj_play_starttime) || !eidos.proj_play_starttime)
			return undefined

		// Use the EIDOS from the child device, but search the group's timetable for the active event - if an event is returned it is for the entire group
		return findEvent(eidos.proj_id, eidos.proj_play_starttime)
	}

	/**
	 * Creates a DevicePlaybackStatus block for the specified device, based on its current eidos status.
	 * @param device The device to create a status block for
	 * @returns the DevicePlaybackStatus block
	 */
	private getPlaybackStatus = async (device: Device) => {
		const getActiveLightshow = async (device: Device) => {
			if (device instanceof DeviceGroup)
				device = device.getChildDevices().find((dev) => dev.isOnline)

			const eidos = device?.eidos

			if (!eidos) return undefined

			if (
				device instanceof DeviceRPi &&
				device.compareVersion("3.2.0") >= 0
			) {
				if (!eidos.player || typeof eidos.player.id == "string")
					return undefined
				const lightshow = DataHandlerLightshow.get(eidos.player.id)
				return lightshow
			} else {
				const lightshow = DataHandlerLightshow.get(eidos.proj_id)
				if (lightshow && !lightshow.duration) {
					await DataHandlerLightshow.pull([eidos.proj_id])
					return await DataHandlerLightshow.get(eidos.proj_id)
				}
				return lightshow
			}
		}

		/**
		 * Gets the scene currently being played from specified device.
		 * @param device
		 * @param lightshow
		 * @returns
		 */
		const getActiveScene = async (
			device: Device,
			lightshow?: Lightshow
		) => {
			if (device instanceof DeviceGroup)
				device = device.getChildDevices().find((dev) => dev.isOnline)

			const eidos = device?.eidos

			if (!eidos) return undefined

			if (
				!lightshow ||
				(device instanceof DeviceRPi &&
					device.compareVersion("3.2.0") >= 0)
			)
				return DataHandlerScene.get(eidos.proj_id)

			const startTime = DateTime.fromMillis(
				device.eidos.proj_play_starttime * 1000,
				{
					zone: "utc",
				}
			)

			const timestamp =
				DateTime.now().diff(startTime).as("seconds") %
				lightshow.duration
			const sequence = lightshow.getScenesAndTimestamps()

			let prevScene = undefined
			for (const [start, scene] of Object.entries(sequence)) {
				const startTime = Number(start)
				if (timestamp < startTime) return prevScene

				prevScene = scene
			}

			// If the loop ended, the last scene is active
			const lastScene =
				Object.values(sequence)[Object.values(sequence).length - 1]
			return lastScene
		}

		const isPlaying = this.checkIsPlaying(device)
		const lightshow = await getActiveLightshow(device)
		const scene = await getActiveScene(device, lightshow)
		const event = await this.getActiveEvent(device)

		await this.checkForTimetableSwitch(device, isPlaying)

		return {
			isPlaying,
			lightshow,
			scene,
			event,
		}
	}

	/**
	 * If the device is stuck in "timetable override" mode, call this to send the revert back to timetable command
	 */
	private async checkForTimetableSwitch(
		device: Device,
		newPlayingStatus: boolean
	) {
		if (!device) return

		const wasPlaying = this.get()[device?.id]?.isPlaying ?? true

		try {
			if (!newPlayingStatus && wasPlaying && device.isOnline)
				await device.platoCall("clear_override", [])
		} catch (e) {
			console.log("Unable to clear override", e)
		}
	}
}

export const DevicePlaybackManager = new DevPlaybackManager()
