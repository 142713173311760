import {
	DataHandlerDevice,
	DataHandlerSnapshot,
	type Device,
	type Snapshot,
} from "luxedo-data"
import { LuxedoRPC } from "luxedo-rpc"
import type { PNCStatus } from "luxedo-rpc/src/routes/deviceControl"
import { Toast } from "svelte-comps/toaster"
import type { Unsubscriber } from "svelte/motion"
import { writable, type Writable, get } from "svelte/store"

class CalibrationProcessingError extends Error {
	images: Array<string>
	constructor(images?: Array<string>) {
		super("Calibration processing failed.")
		this.images = images
	}
}

export type CalibrationProgress = {
	progress: number // % progress of total operation
	message: string // the heading string of the current step
	description: string // the description string of the current step
	didError?: boolean // true if there is a calibration error (used for rendering red text, not stopping the calibration)
}

const WEIGHT = {
	auto_exposure: 0.02,
	PNC: 0.55,
	processing: 0.35,
	snapshot: 0.05,
}

function calculateStepProgress(step: keyof typeof WEIGHT, progress: number) {
	switch (step) {
		case "snapshot":
			return (
				WEIGHT.auto_exposure +
				WEIGHT.PNC +
				WEIGHT.processing +
				WEIGHT.snapshot * progress
			)
		case "processing":
			return (
				WEIGHT.auto_exposure + WEIGHT.PNC + WEIGHT.processing * progress
			)
		case "PNC":
			return WEIGHT.auto_exposure + WEIGHT.PNC * progress
		case "auto_exposure":
			return WEIGHT.auto_exposure * progress
	}
}

/**
 * Handles the state of calibration ONLY during the PNC step.
 */
class PNCTranslator {
	devID: number
	pncID: number
	eidosListenerID: string

	// the interval key - used to clear the listener
	statusInterval: number
	// the error timeout - used to ensure certain errors wait to render
	errorTimeout: number
	// the error waiting to be reported via the timeout
	pendingProgress: string

	// Method to update the proper store with translated calibration progress
	store: Writable<CalibrationProgress>

	constructor(
		devID: number,
		pncID: number,
		store: Writable<CalibrationProgress>
	) {
		this.devID = devID
		this.pncID = pncID
		this.store = store
	}

	/**
	 * Begins the interval to check the pnc status
	 */
	startListening() {
		return new Promise<void>((res, rej) => {
			const finish = (didFail?: boolean) => {
				clearInterval(this.statusInterval)
				if (this.eidosListenerID)
					DataHandlerDevice.get(this.devID)?.removeUpdateListener(
						this.eidosListenerID
					)

				if (didFail) rej()
				else res()
			}

			/**
			 * Processes the pnc state, handling each state relevant to this module (will clear interval on done)
			 * @param status
			 */
			const processStatus = (status: PNCStatus) => {
				console.log("processing calibration status", status)

				let waitToReport: boolean = false
				let newProgress: CalibrationProgress

				const progress = get(this.store).progress

				switch (status.state) {
					case "UNKNOWN_ERROR": // wait 15 seconds and inform user
					case "SEARCHING": // wait 15 seconds and inform user
						waitToReport = true
						newProgress = {
							message:
								"Lost connection with projector... Trying to reconnect...",
							description: `<span class="warn">The connection with your device was lost, but don't worry! If your device is still powered on, it may still be calibrating. Working to reconnect now...</span>`,
							progress,
							didError: true,
						}
						break
					case "LOST": // device has been offline for a while now
						waitToReport = true
						newProgress = {
							message: "Lost connection with projector...",
							description: `The connection with your device was lost. Ensure your device is powered on and connected to the internet then try again`,
							progress,
							didError: true,
						}
						break

					case "WAITING": // basically initializing
						// another compromise
						// occasionally, will receive "WAITING" amidst capturing; should be ok to skip this report, as device eidos updates are being listened to
						if (this.eidosListenerID) return

						newProgress = {
							message: "Initializing...",
							description:
								"The signal was sent to your device to begin calibration... Waiting for calibration to start.",
							progress,
						}
						break

					case "AUTO_EXPOSURE": // camera adjusting
						newProgress = {
							message: "Fine-tuning camera settings...",
							description: `<span class="warn">This step may take up to 10 minutes while your device calculates the best camera settings for your environment.`,
							progress: calculateStepProgress("auto_exposure", 1),
						}
						break

					case "CAPTURING": // capturing as usual
						newProgress = {
							message: `Capturing calibration images (${status.captured} of ${status.total})...`,
							description: `Calibration is the process of shining patterns from a projector in one location, and taking images from a camera in another location, then using mathematical analysis to map the images accurately to the projector.`,
							progress: calculateStepProgress(
								"PNC",
								status.captured / status.total
							),
						}

						// devops_get_pnc_status was not reporting 45/45 and would skip reporting the last image; added this to ensure completed pnc report is received
						this.eidosListenerID = DataHandlerDevice.get(
							this.devID
						)?.addUpdateListener(({ eidos }) => {
							if (
								eidos?.pnc?.imgs_taken === eidos.pnc.img_total
							) {
								newProgress = {
									message:
										"Capturing complete. Sending images to be processed...",
									description: `The calibration images have been successfully captured and are being sent to the processing server now.`,
									progress: calculateStepProgress("PNC", 1),
								}

								finish(false)
							}
						})

						break
					case "DONE": // completed (fail or success)
						if (status.exit_code === "SUCCESS") {
							newProgress = {
								message:
									"Capturing complete. Sending images to be processed...",
								description: `The calibration images have been successfully captured and are being sent to the processing server now.`,
								progress: calculateStepProgress("PNC", 1),
							}
							finish(false)
						} else if (status.exit_code === "NO_CAMERA") {
							newProgress = {
								message: "No camera detected...",
								description: `Unable to capturing calibration images as no camera was detected. Ensure the onboard camera is connected correctly and try again.`,
								progress,
								didError: true,
							}
							finish(true)
						} else if (status.exit_code === "CANCELLED") {
							newProgress = {
								message: "Calibration cancelled...",
								description: `This calibration was cancelled. If you did not cancel it, simply try again. If this continues, reach out to customer support.`,
								progress,
								didError: true,
							}
							finish(true)
						} else {
							newProgress = {
								message: "Capturing failed to complete...",
								description: `Unable to finish capturing calibration images. Restart your device and try again. If this continues, reach out to customer support.`,
								progress,
								didError: true,
							}
							finish(true)
						}
						break
				}

				// Serialize progress and check if error timeout needs to be cancelled

				const serializedProgress = JSON.stringify(newProgress)
				if (this.errorTimeout) {
					if (
						this.pendingProgress &&
						serializedProgress !== this.pendingProgress
					) {
						console.log("Cancelling error timeout")
						clearTimeout(this.errorTimeout)
						this.errorTimeout = undefined
					}
				}

				if (waitToReport) {
					console.warn(
						"[CAL] Waiting to report progress: ",
						newProgress
					)
					this.pendingProgress = serializedProgress
					this.errorTimeout = setTimeout(() => {
						this.store.update((ctx) => ({ ...ctx, ...newProgress }))
						this.pendingProgress = undefined
						this.errorTimeout = undefined
					}, 15000)
				} else {
					this.store.update((ctx) => ({ ...ctx, ...newProgress }))
				}
			}

			/**
			 * Checks the pnc status and provides it to processPNCStatus
			 */
			const checkStatus = async () => {
				const status = await this.getPNCStatus()
				processStatus(status)
			}

			this.statusInterval = setInterval(checkStatus, 1000)
		})
	}

	/**
	 * Simply received the PNC status
	 * @returns The PNC Status
	 */
	async getPNCStatus(): Promise<PNCStatus> {
		return await LuxedoRPC.api.deviceControl.devops_get_pnc_status(
			this.devID,
			this.pncID
		)
	}

	/**
	 * Stops the polling interval
	 */
	stopListening() {
		clearInterval(this.statusInterval)
	}
}

/**
 * Handles the state of calibration ONLY during the processing step.
 */
class CalibrationProcessingTranslator {
	store: Writable<CalibrationProgress>
	pncID: number

	constructor(pncID: number, store: Writable<CalibrationProgress>) {
		this.pncID = pncID
		this.store = store
	}

	/**
	 * Unbinds each cal progress endpoint
	 */
	stopListening() {
		LuxedoRPC.bindEndpoint("calibration_diag_load_cal_imgs", undefined)
		LuxedoRPC.bindEndpoint("calibration_progress", undefined)
		LuxedoRPC.bindEndpoint("snapshot_take_complete", undefined)
		LuxedoRPC.bindEndpoint("calibration_calculation_complete", undefined)
	}

	startListening() {
		return new Promise<Snapshot>((res, rej) => {
			/**
			 * Handles calibration failure, rejecting this promise with the calibration images
			 * @param images
			 */
			const handleFail = async (images?: Array<string>) => {
				if (!images) {
					const res = await LuxedoRPC.api.pnc.get_pnc_images(
						this.pncID
					)
					if (res[1] === 200) images = res[0]
				}

				this.stopListening()
				rej(images)
			}

			LuxedoRPC.bindEndpoint(
				"snapshot_take_complete",
				async (snapshotUrl: string) => {
					const urlParts: Array<string> = snapshotUrl.split("/")
					const idString =
						urlParts[urlParts.length - 1].split("S.jpg")[0]
					const snapshotID = Number(idString)

					await DataHandlerSnapshot.pull([snapshotID])

					this.store.update((ctx) => ({
						...ctx,
						message: "Calibration Complete",
						description:
							"This image has been transformed using the calibration algorithm to accurately reflect the view from the projector's perspective, ensuring precise alignment with your projection space.",
						didError: false,
						progress: calculateStepProgress("snapshot", 1),
					}))

					const snapshot = DataHandlerSnapshot.get(snapshotID)

					this.stopListening()
					res(snapshot)
				}
			)

			LuxedoRPC.bindEndpoint(
				"calibration_diag_load_cal_imgs",
				(data: { [index: number]: string }) => {
					const images = Object.values(data)

					this.store.update((ctx) => ({
						...ctx,
						message:
							"Calibration failed while processing images...",
						description:
							"Calibration unable to finish as the captured images did not provide the necessary data. This can happen if the device or camera were moved during this process. Click 'Troubleshoot' to investigate.",
						didError: true,
					}))

					handleFail(images)
				}
			)

			LuxedoRPC.bindEndpoint("calibration_progress", (data: number) => {
				this.store.update((ctx) => ({
					...ctx,
					message: "Processing images...",
					description:
						"Calibration is the process of shining patterns from a projector in one location, and taking images from a camera in another location, then using mathematical analysis to map the images accurately to the projector.",
					progress: calculateStepProgress("processing", data),
				}))
			})

			LuxedoRPC.bindEndpoint(
				"calibration_calculation_complete",
				(validInt: number, errorCode: number) => {
					const isValid = !!validInt
					if (isValid) {
						this.store.update((ctx) => ({
							...ctx,
							didError: false,
							progress: calculateStepProgress("processing", 1),
							message: "Capturing snapshot...",
							description:
								"Calibration is almost complete! Now, we'll capture one final image to accurately map the projection space. This image will be transformed using the calibration result to give you a precise view of the projector's perspective.",
						}))
					} else {
						switch (errorCode) {
							case 3:
								this.store.update((ctx) => ({
									...ctx,
									message:
										"Unable to finish calibrating with captured images...",
									description:
										"Not enough of the projection space was captured by the calibration camera. Please ensure the camera and projector are fully covering the projection space.",
									didError: true,
								}))
								handleFail()
								break
							case 4:
								this.store.update((ctx) => ({
									...ctx,
									message: "No projection detected...",
									description:
										"No projection was detected by the calibration camera. Please ensure the camera is facing the projection space and the projector is connected to and receiving the input from your Luxedo device.",
									didError: true,
								}))
								handleFail()
								break
							default:
								this.store.update((ctx) => ({
									...ctx,
									message:
										"Unable to finish processing calibration...",
									description:
										"The images captured during the calibration process do not appear to provide the needed information to finish this calibration. If this continues, contact support.",
									didError: true,
								}))
								handleFail()
								break
						}
					}
				}
			)
		})
	}
}

/**
 * Handles ALL device calibrations - this is the central source of truth for components regarding calibration.
 */
export namespace DeviceCalibrationManager {
	export type ContextType = CalibrationProgress

	const CONTEXT_DEFAULT: ContextType = {
		progress: 0,
		message: "Initializing...",
		description: `Calibration is the process of shining patterns from a projector in one location, and taking images from a camera in another location, then using mathematical analysis to map the images accurately to the projector.`,
		didError: false,
	}

	// Each calibration instance store (key = device ID)
	let stores: { [index: number]: Writable<ContextType> } = {}
	// project and capture listeners
	let pncListeners: { [index: number]: PNCTranslator } = {}
	// progress listeners
	let prgListeners: { [index: number]: CalibrationProcessingTranslator } = {}
	// a mapping of device IDs to PNC IDs
	let pncDeviceMap: { [index: number]: number } = {} // [device.id] : pnc.id

	/**
	 * Calls calibrate and returns the store for this calibration instance
	 * @param device The device to calibrate
	 * @param onSuccess Callback when complete
	 * @param onFailure Callback on error
	 * @returns calibration progress store
	 */
	export function startCalibration(
		device: Device,
		onSuccess: (snap: Snapshot, deviceID: number) => void,
		onFailure: (images: Array<string> | undefined, deviceID: number) => void
	) {
		if (stores[device.id]) delete stores[device.id]

		// Create new calibration store for this instance
		const deviceStore = writable<ContextType>(CONTEXT_DEFAULT)
		stores[device.id!] = deviceStore

		calibrate(device)
			.then((snap) => {
				onSuccess(snap, device.id)
			})
			.catch((error) => {
				onFailure(error.images, device.id)
			})

		return deviceStore
	}

	/**
	 * Cancels a calibration for the specified device
	 * @param device the device to cancel calibration
	 */
	export async function cancelCalibration(device: Device) {
		await LuxedoRPC.api.deviceControl.devops_cal_cancel(device.id)
		Toast.success("Calibration cancelled.")
		cleanUp(device)
	}

	/**
	 * Triggers a calibration for the provided device, initializing a new store and listener instances (see classes above) to report progress.
	 * @param device The device to calibrate
	 * @returns the resulting snapshot
	 * @throws CalibrationProcessingError w/ images | generic Error
	 */
	async function calibrate(device: Device) {
		const deviceStore = stores[device.id]

		// ensure this session is listening to device updates (not sure if this is even necessary as eidos updates are no longer needed)
		LuxedoRPC.addCustomData("maintain_control_of", device.id!)
		await LuxedoRPC.api.deviceControl.device_control_aquire(device.id!, 1)

		// initialize the calibration
		const pncID = (
			await LuxedoRPC.api.deviceControl.devops_cal_start(device.id)
		).pnc_id
		pncDeviceMap[device.id] = pncID

		try {
			// begin listening to pnc updates
			pncListeners[device.id] = new PNCTranslator(
				device.id,
				pncID,
				deviceStore
			)
			await pncListeners[device.id].startListening()

			// begin listening to calibration progress updates once pnc is complete
			prgListeners[device.id] = new CalibrationProcessingTranslator(
				pncID,
				deviceStore
			)
			const snapshot = await prgListeners[device.id].startListening()

			console.log("DEVICE CAL MANAGER GOT SNAPSHOT", snapshot)

			return snapshot
		} catch (e) {
			console.error("Error while calibrating", e)
			if (e && e instanceof Array) throw new CalibrationProcessingError(e)
			else throw new Error("Unable to complete calibration.")
		}
	}

	/**
	 * Stops all listeners and sets state to default
	 * @param device the device to stop listening to
	 */
	export function cleanUp(device: Device) {
		stores[device?.id]?.set(CONTEXT_DEFAULT)
		pncListeners[device?.id]?.stopListening()
		delete pncListeners[device?.id]
		prgListeners[device?.id]?.stopListening()
		delete prgListeners[device?.id]
	}

	/**
	 * Gets the latest calibration images for the provided device
	 * @param device the device who's pnc images are being requested
	 * @returns an array of image URLs or undefined
	 */
	export async function getCalibrationImages(device: Device) {
		const pncID = pncDeviceMap[device.id]
		try {
			const res = await LuxedoRPC.api.pnc.get_pnc_images(pncID)
			if (res[1] === 200) return res[0]
			else return undefined
		} catch (e) {
			console.error("An error occurred while getting PNC images", e)
			return undefined
		}
	}
}
